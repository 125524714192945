<template>
    <b-overlay>
        <b-col>
            <b-row>
                <b-col cols="6">
                    <b-card title="Filter">
                        <b-form-group label="Minimalna dolžina kosa: (0 = vse)">
                            <b-form-input v-model="statisticsRequestData.min_length" type="number" size="m"/>
                        </b-form-group>
                        <b-form-group label="Maksimalna dolžina kosa: (0 = vse)">
                            <b-form-input v-model="statisticsRequestData.max_length" type="number" size="m"/>
                        </b-form-group>
                        <b-button variant="primary" class="mb-1" @click="statisticsRequestData.material_ids = paneliZaNaročanje.map(it => {return {value: it, text: materialsFiltered.find(material => material.value === it).text}})">Izberi panele za naročanje</b-button>
                        <b-button variant="danger" class="mb-1 ml-1" @click="statisticsRequestData.material_ids = []">Počisti izbiro</b-button>

                        <b-form-group label="Materiali">
                            <b-form-input v-model="materialsFilter" type="text" size="sm" placeholder="Išči"/>
                            <v-select class="mt-1" v-model="statisticsRequestData.material_ids" :options="materialsFiltered" multiple :get-option-label="(option) => {return `${option.text} - ${option.value}`}"/>
                        </b-form-group>
                        <b-form-group label="Datum od">
                            <b-form-datepicker value-as-date v-model="statisticsRequestData.date_from" size="sm"/>
                        </b-form-group>
                        <b-form-group label="Datum do">
                            <b-form-datepicker value-as-date v-model="statisticsRequestData.date_to" size="sm"/>
                        </b-form-group>
                        <b-button @click="loadData" variant="primary">Prikaži</b-button>
                    </b-card>
                </b-col>
                <b-col cols="6">
                    <b-card title="Košare za dolžine kosov" cols="6">
                        <b-col>
                            <b-form-checkbox v-model="useBuckets">Uporabi košare?</b-form-checkbox>
                            <b-row class="mt-1">
                                <b-col>
                                    <b-form-group label="Velikost košare v mm"> <b-form-input v-model.number="bucketSize" type="number" debounce="500"/></b-form-group>
                                </b-col>

                            </b-row>
                        </b-col>


                    </b-card>
                    <b-card title="Hitra statistika">
                        <p>Skupno število kosov: {{filtered.reduce((acc, item) => acc + item.quantity, 0)}}</p>
                        <p>Skupna kvadratura: {{(filtered.reduce((acc, item) => acc + item.total_surface, 0) / 1000000).toFixed(2)}}m²</p>
                    </b-card>
                    <b-card title="Izvoz">
                        <b-button @click="exportData()" variant="success">Izvozi excel</b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
        <b-col cols="12">
            <b-card title="Izhodni kosi">

                <b-table striped responsive="sm" bordered hover :items="groupedFiltered" :fields="fields" :filter="search"
                         :per-page="perPage" :current-page="currentPage">
                    <template slot="top-row" slot-scope="{ fields }">
                        <b-td v-for="field in fields" :key="field.key">
                            <b-form-input v-if="field.key != 'actions'" size="sm" v-model="filters[field.key]"
                                          :placeholder="field.label"/>
                        </b-td>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" @click="openDetailsModal(data.item)" variant="primary">Podrobnosti</b-button>
                        <b-button size="sm" @click="filterGraph(data.item.base_id !== '' ? data.item.base_id : data.item.id)" class="ml-1" variant="info">Graf</b-button>
                    </template>
                    <template v-slot:cell(total_surface)="data">
                        {{(data.item.total_surface / 1000000).toFixed(2)}}m2
                    </template>
                </b-table>
                <b-row>
                    <b-col class="float-left">
                        <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                            <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                                             @click="setPerPage(item)">
                                {{ item }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="7" md="6" class="float-right">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col cols="12">
            <b-card :title="`Graf ${useBuckets ? 's košarami' : 'brez košar'} ${shouldFilterGraph ? 'za material ' +graphFilter : ''}`">
                <b-button v-if="this.graphFilter" @click="resetGraphFilter()" variant="danger">Resetiraj graf</b-button>
                <apexchart type="bar" :series="series" :options="options" :categories="categories" height="600px"></apexchart>
            </b-card>
        </b-col>

        <b-modal title="Podrobnosti materiala" v-model="stockModalActive" size="lg" centered hide-footer>
            <b-card>
                <table>
                    <tr>
                        <th class="pb-50">ID</th>
                        <td class="pb-50 pl-1">{{ stock.id }}</td>
                    </tr>
                    <tr v-if="stock.lengths">
                        <th class="pb-50">Dolžine</th>
                        <td class="pb-50 pl-1">
                            <ul>
                                <li :key="mapItem" v-for="mapItem in stock.lengths.reduce((accMap, curr) =>
                                    accMap.set(curr.length, curr.quantity +  (accMap.get(curr.length) > 0 ? accMap.get(curr.length) : 0) ), new Map).entries()">
                                    {{ mapItem[1] }}x {{ mapItem[0] }}mm
                                </li>
                            </ul>
                        </td>
                    </tr>

                </table>
            </b-card>
        </b-modal>
    </b-overlay>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import vSelect from 'vue-select'
    import fuzzysort from 'fuzzysort'
    export default {
        components: {apexchart: VueApexCharts, vSelect},
        data() {
            return {
                statisticsRequestData: {
                    date_from: new Date(2024, 0, 1),
                    date_to: new Date(),
                    min_length: 0,
                    max_length: 0,
                    material_ids: []
                },
                paneliZaNaročanje: [
                    'PAN-04-1101-045050',
                    'PAN-04-1230-045500',
                    'PAN-04-1240-045050',
                    'PAN-04-1250-045050',
                    'PAN-04-1260-045050',
                    'PAN-04-6120-050500',
                    'PAN-06-1045-045050',
                    'PAN-06-1051-040605',
                    'PAN-06-1230-045500',
                    'PAN-06-1232-045500',
                    'PAN-09-1045-040505',
                    'PAN-09-1046-040505',
                    'PAN-09-1101-040505',
                    'PAN-09-1230-045500',
                    'PAN-09-1240-045050',
                    'PAN-09-1250-045050',
                    'PAN-09-1260-045050',
                    'TRA-00-0600-550080',
                    'TRA-04-1001-403050',
                    'TRA-04-1034-405050',
                    'TRA-04-1044-405060',
                    'TRA-04-2000-403060',
                    'TRA-04-2034-400605',
                    'TRA-05-1001-403050',
                    'TRA-05-2000-403060',
                    'TRA-05-2010-401006',
                    'TRA-05-6002-340605',
                    'TRA-05-6002-405050',
                    'TRA-06-1001-403050',
                    'TRA-06-1050-405050',
                    'TRA-06-2000-163060',
                    'TRA-06-2010-401006',
                    'TRA-06-6002-340505',
                    'TRA-06-6002-340605',
                    'TRA-07-1001-403050',
                    'TRA-07-1034-405050',
                    'TRA-07-2000-403060',
                    'TRA-07-2034-400605',
                    'TRA-09-1001-403050',
                    'TRA-09-1034-405050',
                    'TRA-09-2000-403060',
                    'TRA-09-2010-401006',
                    'TRA-09-2034-406050',
                    'TRA-09-6002-340605',
                    'TRA-99-1000-402503',
                    'TRA-99-1005-400012'
                ],
                materials: [],
                useBuckets: true,
                materialsFilter: '',
                statisticsData: {items: [], items_grouped: []},
                search: '',
                bucketSize: 500,
                stockModalActive: false,
                stock: {material_type: {}},
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                filters: {id: ''},
                fields: [
                    {key: 'id', label: 'Šifra', class: 'text-center', filterable: true, sortable: true},
                    {key: 'title', label: 'Naziv', class: 'text-center', filterable: true, sortable: true},
                    {
                        key: 'quantity',
                        label: 'Št. kosov',
                        class: 'text-center',
                        sortable: true
                    },
                    {key: 'total_surface', label: 'm2', class: 'text-center', sortable: true},
                    {
                        key: 'actions',
                        label: '',
                        class: 'text-center'
                    }
                ],
                graphFilter: '',
                shouldFilterGraph: false
            }
        },
        methods: {
            loadData() {
                this.$store.state.app.shallShowOverlay = true
                const thisIns = this
                this.$http.post('/api/v1/user/statistics/', {
                    date_from: thisIns.statisticsRequestData.date_from.toISOString(),
                    date_to: thisIns.endDateWithTime.toISOString(),
                    min_length: Number(thisIns.statisticsRequestData.min_length),
                    max_length: Number(thisIns.statisticsRequestData.max_length),
                    bucket_size: Number(thisIns.bucketSize),
                    material_ids: thisIns.statisticsRequestData.material_ids.map(item => item.value)
                }).then(function(response) {
                    thisIns.statisticsData = response.data

                }).catch(function(error) {
                    thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                }).finally(() => {
                    this.$store.state.app.shallShowOverlay = false
                })
            },
            loadMaterials() {
                const thisIns = this
                this.$http.get('/api/v1/user/material/base').then(function(response) {
                    thisIns.materials = response.data.filter(it => it.saw_group_id !== '').map(item => ({text: item.material, value: item.id}))
                }).catch(function(error) {
                    thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                })
            },
            setPerPage(item) {
                this.perPage = item
            },
            openDetailsModal(item) {
                if (item !== null) {
                    this.stock = item
                    this.stockModalActive = true
                }
            },
            filterGraph(id) {
                this.graphFilter = id
                this.shouldFilterGraph = true

            },
            resetGraphFilter() {
                this.shouldFilterGraph = false
                this.graphFilter = null
            },
            exportData() {
                const thisIns = this
                this.$http.post('/api/v1/user/statistics/export', {
                    date_from: thisIns.statisticsRequestData.date_from.toISOString(),
                    date_to: thisIns.endDateWithTime.toISOString(),
                    min_length: Number(thisIns.statisticsRequestData.min_length),
                    max_length: Number(thisIns.statisticsRequestData.max_length),
                    bucket_size: Number(thisIns.bucketSize),
                    material_ids: thisIns.statisticsRequestData.material_ids.map(item => item.value)
                }, {responseType: 'blob'}).then(res => {
                    const blob = new Blob([res.data], {type: res.headers['content-type']})
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'statistika.xlsx'
                    link.click()
                }).catch(function(error) {
                    thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                })
            }
        },
        computed: {
            endDateWithTime() {
                const date = new Date(this.statisticsRequestData.date_to)
                date.setHours(23, 59, 59, 999)
                return date
            },
            buckets() {
                const buckets = []
                for (let i = 0; i < 15000; i += this.bucketSize) {
                    buckets.push({from: i, to: i + this.bucketSize})
                }
                return buckets
            },
            filtered() {
                return this.statisticsData.items.filter(item => {
                    return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
            },
            groupedFiltered() {
                return this.statisticsData.items_grouped.filter(item => {
                    return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
            },
            materialsFiltered() {
                return this.materials.filter(item => item.text.toLowerCase().includes(this.materialsFilter.toLowerCase()) || item.value.toLowerCase().includes(this.materialsFilter.toLowerCase()) || fuzzysort.single(this.materialsFilter, item.text) !== null)
            },
            series() {
                const series = [{name: 'lengths', data: []}]
                const thisIns = this
                try {
                    if (this.useBuckets) {
                        const buckets = this.buckets.map(() => 0)
                        this.filtered.filter(it => {
                            if (this.shouldFilterGraph) {
                                return it.id === this.graphFilter || it.base_id === this.graphFilter
                            } else {
                                return true
                            }
                        }).forEach(function(item) {
                            const length = item.lengths.reduce((accMap, curr) => accMap.set(curr.length, curr.quantity + (accMap.get(curr.length) > 0 ? accMap.get(curr.length) : 0)), new Map)
                            length.forEach((value, key) => {
                                let bucketIndex = 0
                                for (let i = 0; i < 500; i++) {
                                    if (thisIns.buckets[i].from < key && key <= thisIns.buckets[i].to) {
                                        bucketIndex = i
                                        break
                                    }
                                }
                                buckets[bucketIndex] += value
                            })
                        })
                        series[0].data = buckets
                    } else {
                        this.filtered.filter(it => {
                            if (this.shouldFilterGraph) {
                                return it.id === this.graphFilter || it.base_id === this.graphFilter
                            } else {
                                return true
                            }
                        }).forEach(function(item) {
                            series[0].data.push(item.quantity)
                        })
                    }
                    
                    return series
                } catch (e) {
                    return series
                }
            },
            categories() {
                const categories = []
                try {
                    if (this.useBuckets) {
                        this.buckets.forEach(function(item) {
                            categories.push(`${(item.from / 1000).toFixed(1)} - ${item.to / 1000} m`)
                        })
                    } else {
                        this.filtered.filter(it => {
                            if (this.shouldFilterGraph) {
                                return it.id === this.graphFilter || it.base_id === this.graphFilter
                            } else {
                                return true
                            }
                        }).forEach(function(item) {
                            categories.push(item.title)
                        })
                    }

                    return categories
                } catch (e) {
                    return categories
                }
            },
            options() {
                return {
                    chart: {
                        id: 'materials'
                    },
                    xaxis: {
                        categories: this.categories,
                        labels: {
                            maxWidth: 480
                          
                        }
                    },
                    yaxis: {
                        labels: {
                            maxWidth: 600
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    }
                }
            },
            lengths() {
                return this.filtered.reduce((acc, item) => {

                    acc.push(item.lengths)

                    return acc
                }, [])
            },
            totalRows() {
                return this.filtered.length
            }
        },
        mounted() {
            this.loadData()
            this.loadMaterials()
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
